import { Button, Divider, Form } from '@/UI';
import { useState } from 'react';
import { ForgottenPassword } from '../forgotten-password';
import { useRouter } from 'next/router';
import { APIResponse } from '@/interfaces';
import { showMessage } from '@/components/messages/GMessage';
import { login, loginWith3rdParty } from '@/services/Auth.service';
import LoginButton3rd from './components/LoginButton3rd';
import { GHTKIcon, GoogleIcon } from '@/components/icons';
import CustomInput from '@/UI/CustomInput';

import ChatCSKH from '@/modules/public-modules/login/components/ChatCSKH';
import OpenApp from '@/modules/public-modules/login/components/OpenApp';

const LoginCard = () => {
  const router = useRouter();
  const [form] = Form.useForm();
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [openChat, setOpenChat] = useState(false);

  const handleShowForgetPassword = () => {
    setShowForgotPassword(true);
  };

  const handleCloseForgetPassword = () => {
    setShowForgotPassword(false);
  };

  const handleLoginWith3rdParty = async (type) => {
    const res: APIResponse = await loginWith3rdParty({ type });

    if (res.error) return showMessage.error(res.error);

    window.location.href = res.data.base_url;
  };

  const onGamLogin = async () => {
    const res: APIResponse = await login(form.getFieldsValue());

    if (res.error) return showMessage.error(res.error);
    router.push('/home');
  };

  const onOpenCSKH = () => {
    setShowForgotPassword(false);
    setOpenChat(true);
  };

  return (
    <>
      <div className="flex flex-col items-center h-[60vh] w-full">
        <p className="mt-8 text-2xl font-medium mb-1 text-center">
          Chào bạn đến với Apollo: Giải pháp ứng dụng AI
        </p>
        <p className="text-th-sub-text mb-8 text-center">
          Nhập số điện thoại, email và mật khẩu để tạo tài khoản
        </p>
        <Form
          form={form}
          className="flex flex-col items-center w-full xl:gap-y-5 gap-y-3 mb-4"
          onFinish={onGamLogin}
        >
          <Form.Item
            className="w-full m-0"
            name="username"
            rules={[{ required: true, message: 'Vui lòng nhập số điện thoại hoặc email!' }]}
          >
            <CustomInput
              placeholder="Nhập số điện thoại hoặc email"
              className="w-full"
              allowClear
              autoFocus
            />
          </Form.Item>

          <Form.Item
            className="w-full lg:mb-0"
            name="password"
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
          >
            <CustomInput type="password" placeholder="Nhập mật khẩu" />
          </Form.Item>
          <div
            className="w-full flex justify-start text-th-primary hover:cursor-pointer"
            onClick={handleShowForgetPassword}
          >
            Quên mật khẩu
          </div>

          <Button
            size="large"
            htmlType="submit"
            type="primary"
            className=" w-full bg-th-primary dark:text-black h-[48px]"
          >
            Đăng nhập
          </Button>
          <span>
            Bạn chưa có tài khoản?{' '}
            <span
              className="text-th-primary hover:cursor-pointer font-medium"
              onClick={() => router.push('/signup')}
            >
              Đăng ký
            </span>
          </span>
        </Form>
        <OpenApp />
        <Divider>
          <span className="text-sm text-th-text-disabled font-normal">Hoặc tiếp tục với</span>
        </Divider>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2 mt-1 mb-3">
          <LoginButton3rd icon={<GHTKIcon />} text="GHTK" onClick={() => handleLoginWith3rdParty('ghtk')} />
          <LoginButton3rd
            icon={<GoogleIcon />}
            text="Google"
            onClick={() => handleLoginWith3rdParty('google')}
          />
          {/* <LoginButton3rd*/}
          {/*  icon={<AppleIcon />}*/}
          {/*  text="Apple"*/}
          {/*  onClick={() => handleLoginWith3rdParty('apple')}*/}
          {/*/ >*/}
        </div>

        <ChatCSKH openChat={openChat} setOpenChat={setOpenChat} />
      </div>
      <ForgottenPassword
        onClose={handleCloseForgetPassword}
        open={showForgotPassword}
        onOpenCSKH={onOpenCSKH}
      />
    </>
  );
};

export default LoginCard;
